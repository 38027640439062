<template>
  <section class="ftco-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-5">
          <div class="wrap">
            <div class="img" :style="`background-image:url(${$api.ste.logo})`"></div>
            <div class="login-wrap p-4 p-md-5">
              <div class="d-flex">
                <div class="w-100">
                  <h3 class="mb-4">S'identifier</h3>
                </div>
              </div>
              <form ref="form" class="signin-form">
                <div class="form-group mt-3">
                  <input v-model="user.email" type="email" class="form-control" required="">
                  <label class="form-control-placeholder" for="username">Email</label>
                </div>
                <div class="form-group">
                  <input v-model="user.password" ref="password" type="password" class="form-control" required="">
                  <label class="form-control-placeholder" for="password">Mot de passe</label>
                  <span @click="changeType()" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                </div>
                <div class="form-group d-md-flex">
                  <div class="w-100 text-left">
                    <label class="checkbox-wrap checkbox-primary mb-0">Se souvenir de moi
                      <input v-model="user.forgot" type="checkbox" checked="">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <a class="form-control btn btn-primary rounded submit px-3" @click="login()">
                    <span v-if="loader_log === false">Connexion</span>
                    <span v-else><i class="fas fa-spinner fa-spin"></i></span>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Login",
  data(){
    return {
      loader_log:false,
      user:{
        email:null,
        password:null,
        forgot: false
      }
    }
  },
  components: {
  },
  beforeMount() {
    //do something before mounting vue instance
    if(localStorage.credentials != undefined){
      var user = JSON.parse(localStorage.credentials);
      this.user = user;
    }
  },
  methods: {
    changeType(){
      var type = this.$refs.password.type;
      type = type === 'password' ? 'text' : 'password';
      this.$refs.password.type = type;
    },
    login() {
      if(this.$refs.form.reportValidity() === false){
        return false;
      }
      if(this.loader_log === true){
        return false;
      }
      this.loader_log = true;
      this.$api.ajax('/authorization/create', this.user, res => {
        if(res.status === true){
          if(this.user.forgot === true){
            var user = JSON.stringify(this.user);
            localStorage.credentials = user
          }
          this.$api.saveToken(res.data.token);
          this.$api.saveUser(res.data);
          this.$router.push({path:"/agenda/mois"});
        }
        this.loader_log = false;
      })
    }
  }
};
</script>
<style scoped>
*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
section {
    display: block;
}
h2,
h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: #0056b3;
    text-decoration: underline;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
button {
    border-radius: 0;
}
button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}
input,
button {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button,
input {
    overflow: visible;
}
button {
    text-transform: none;
}
button,
[type="submit"] {
    -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}
input[type="checkbox"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}
h2,
h3 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
h2 {
    font-size: 2rem;
}
h3 {
    font-size: 1.75rem;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col-md-6,
.col-md-7,
.col-lg-5 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
}
@media (min-width: 992px) {
    .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .form-control {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}
.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control:disabled {
    background-color: #e9ecef;
    opacity: 1;
}
.form-group {
    margin-bottom: 1rem;
}
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .btn {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}
.btn:hover {
    color: #212529;
    text-decoration: none;
}
.btn:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn:disabled {
    opacity: 0.65;
}
.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}
.btn-primary:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.rounded {
    border-radius: 0.25rem !important;
}
.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}
@media (min-width: 768px) {
    .d-md-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
}
.justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}
.justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}
.align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}
.w-50 {
    width: 50% !important;
}
.w-100 {
    width: 100% !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
.mb-4 {
    margin-bottom: 1.5rem !important;
}
.mb-5 {
    margin-bottom: 3rem !important;
}
.px-3 {
    padding-right: 1rem !important;
}
.px-3 {
    padding-left: 1rem !important;
}
.p-4 {
    padding: 1.5rem !important;
}
@media (min-width: 768px) {
    .p-md-5 {
        padding: 3rem !important;
    }
}
.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
@media (min-width: 768px) {
    .text-md-right {
        text-align: right !important;
    }
}
@media print {
    *,
    *::before,
    *::after {
        text-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
    .container {
        min-width: 992px !important;
    }
}
a {
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    color: #b1b1b1;
}
a:hover,
a:focus {
    text-decoration: none !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}
a:hover,
a:active,
a:focus {
    color: #b1b1b1;
    outline: none !important;
    text-decoration: none !important;
}
h2,
h3 {
    line-height: 1.5;
    font-weight: 400;
    font-family: "Lato", Arial, sans-serif;
    color: #000;
}
.ftco-section {
    padding: 7em 0;
}
.heading-section {
    font-size: 28px;
    color: #000;
}
.img {
    background-repeat: no-repeat;
    background-position: center center;
}
.wrap {
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 34px -15px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 10px 34px -15px rgba(0, 0, 0, 0.24);
    box-shadow: 0 10px 34px -15px rgba(0, 0, 0, 0.24);
}
.wrap .img {
    height: 150px;
}
.login-wrap {
    position: relative;
}
.login-wrap h3 {
    font-weight: 300;
}
.form-group {
    position: relative;
    z-index: 0;
    margin-bottom: 20px !important;
}
.form-group a {
    color: gray;
}
.form-control {
    height: 48px;
    background: #fff;
    color: #000;
    font-size: 16px;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.form-control::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.2) !important;
}
.form-control::-moz-placeholder {
    color: rgba(0, 0, 0, 0.2) !important;
}
.form-control:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.2) !important;
}
.form-control:-moz-placeholder {
    color: rgba(0, 0, 0, 0.2) !important;
}
.form-control:focus,
.form-control:active {
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #b1b1b1;
}
.field-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.3);
}
.form-control-placeholder {
    position: absolute;
    top: 2px;
    padding: 7px 0 0 15px;
    -webkit-transition: all 400ms;
    -o-transition: all 400ms;
    transition: all 400ms;
    opacity: 0.6;
}
.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
    -webkit-transform: translate3d(0, -120%, 0);
    transform: translate3d(0, -120%, 0);
    padding: 7px 0 0 0;
    opacity: 1;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    color: #b1b1b1;
    font-weight: 700;
}
.social-media {
    position: relative;
    width: 100%;
}
.social-media .social-icon {
    display: block;
    width: 40px;
    height: 40px;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.05);
    font-size: 16px;
    margin-right: 5px;
    border-radius: 50%;
}
.social-media .social-icon span {
    color: #999;
}
.social-media .social-icon:hover,
.social-media .social-icon:focus {
    background: #b1b1b1;
}
.social-media .social-icon:hover span,
.social-media .social-icon:focus span {
    color: #fff;
}
.checkbox-wrap {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.checkbox-wrap input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
}
.checkmark:after {
    content: "\f0c8";
    font-family: "FontAwesome";
    position: absolute;
    color: rgba(0, 0, 0, 0.1);
    font-size: 20px;
    margin-top: -4px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
@media (prefers-reduced-motion: reduce) {
    .checkmark:after {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}
.checkbox-wrap input:checked ~ .checkmark:after {
    display: block;
    content: "\f14a";
    font-family: "FontAwesome";
    color: rgba(0, 0, 0, 0.2);
}
.checkbox-primary {
    color: #b1b1b1;
}
.checkbox-primary input:checked ~ .checkmark:after {
    color: #b1b1b1;
}
.btn {
    cursor: pointer;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 15px;
    padding: 10px 20px;
}
.btn:hover,
.btn:active,
.btn:focus {
    outline: none;
}
.btn.btn-primary {
    background: #b1b1b1 !important;
    border: 1px solid #b1b1b1 !important;
    color: #fff !important;
}
.btn.btn-primary:hover {
    border: 1px solid #b1b1b1;
    background: transparent;
    color: #b1b1b1;
}
/*! CSS Used fontfaces */
@font-face {
    font-family: "FontAwesome";
    src: url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.eot?v=4.7.0");
    src: url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.eot#iefix&v=4.7.0") format("embedded-opentype"),
        url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"),
        url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"),
        url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh7USSwaPGQ3q5d0N7w.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
